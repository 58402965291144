import { IonCol, IonRow } from '@ionic/react';
import Page from '../components/Page';
import '../features/timeTracking/TimeTracking.css';
import DateRangeSelector from '../features/timeTracking/components/DateRangeSelector';
import EmployeeSelector from '../features/timeTracking/components/EmployeeSelector';
import TimeTrackingReports from '../features/timeTracking/components/TimeTrackingReports';
import TimeTrackingToggle from '../features/timeTracking/components/TimeTrackingToggle';
import ViewSelector from '../features/timeTracking/components/ViewSelector';
import useGraphqlQuery from '../hooks/useGraphqlQuery';
import { getAllPeople } from '../features/people/peopleApi';

import '../features/timeTracking/components/datetimeTheme.css';

const TimeTrackingPage: React.FC = () => {
  const { data: people } = useGraphqlQuery(getAllPeople);

  return (
    <Page title="Otuvy Frontline Time Tracking">
      <div>
        <TimeTrackingToggle />
        <h2>Otuvy Frontline Time Tracking Reports</h2>
        <IonRow className="ion-justify-between ion-no-wrap">
          <IonCol sizeXs="12" sizeMd="8" sizeLg="4">
            <DateRangeSelector />
          </IonCol>
          <IonCol sizeXs="12" sizeMd="8" sizeLg="4">
            <EmployeeSelector people={people ?? []} />
          </IonCol>
          <IonCol sizeXs="12" sizeMd="8" sizeLg="4">
            <ViewSelector />
          </IonCol>
        </IonRow>
        <TimeTrackingReports people={people ?? []} />
      </div>
    </Page>
  );
};

export default TimeTrackingPage;
