import { graphql } from '../../../utils/api/apiUtils';
import { logFriendlyObject } from '@otuvy/common-utils';

export interface OrganizationQmIntegration {
  isConnected: boolean;
  defaultListOwner?: string;
  generateLists?: boolean;
  newLocationsGenerateLists?: boolean;
}

export const getIntegrationInfo = async (): Promise<OrganizationQmIntegration> => {
  const query = `
    query getIntegrationInfo {
      getOrganizationQmIntegration {
        isConnected
        defaultListOwner
        generateLists
        newLocationsGenerateLists
      }
    }
  `;
  const info = await graphql<OrganizationQmIntegration>(query);
  console.log('got integration info', logFriendlyObject(info));
  return info;
};

export interface LinkResult {
  serviceProviderId?: string;
  error?: number;
  errorDetail?: string;
}

export const linkOrgToQm = async (qmJWT: string, defaultListOwner: string): Promise<LinkResult> => {
  const query = `
    mutation linkToQMMutation($qmJWT: String!, $defaultListOwner: ID!) {
      linkToQM(qmJWT: $qmJWT, defaultListOwner: $defaultListOwner) {
        serviceProviderId
        error
        errorDetail
      }
    }
  `;
  const variables = { qmJWT, defaultListOwner };
  const result = await graphql<LinkResult>(query, variables);
  console.log('organization linked to QM');
  return result;
};

export const disconnectIntegration = async () => {
  const query = `
    mutation disconnectIntegration {
      unlinkFromQM
    }
  `;
  await graphql(query);
  console.log('integration disconnected');
};

export const updateDefaultListOwner = async (defaultListOwner: string) => {
  const query = `
    mutation updateQMDefaultListOwner($defaultListOwner: ID!) {
      updateQMDefaultListOwner(defaultListOwner: $defaultListOwner)
    }
  `;
  const variables = {
    defaultListOwner,
  };
  await graphql(query, variables);
  console.log('default list owner updated');
};

export interface QmLocation {
  serviceLocationId: string;
  clientName: string;
  serviceLocationName: string;
  autoGenerateLists: boolean;
  defaultListOwner: string | null;
}

export const getQmServiceLocations = async (): Promise<QmLocation[]> => {
  const query = `
    query getQmServiceLocations {
      getQmServiceLocations {
        serviceLocationId
        serviceLocationName
        clientName
        autoGenerateLists
        defaultListOwner
      }
    }
  `;
  console.log('Querying remote server for QM service locations');
  const locations = await graphql<QmLocation[]>(query);
  console.log(`Remote server returned ${locations ? locations.length : null} QM service locations`);
  return locations;
};

export interface UpdateQmServiceLocationInput {
  serviceLocationId: string;
  autoGenerateLists: boolean;
  defaultListOwner: string | null;
}

export const updateQmServiceLocation = async (input: UpdateQmServiceLocationInput) => {
  const query = `
    mutation updateQmServiceLocation($input: UpdateQmServiceLocationInput!) {
      updateQmServiceLocation(input: $input)
    }
  `;
  const variables = { input };
  await graphql(query, variables);
  console.log('QM service location updated');
};
