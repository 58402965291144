import { IonImg, IonToggle } from '@ionic/react';
import IntegrationsTabsPage from './IntegrationsTabsPage';

import appLogo from '../../../assets/images/qm-logo.png';
import PersonPickerSearch from '../../../components/personPicker/PersonPickerSearch';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import QmIntegrationInstructions from '../../../features/integrations/qm/general/QmIntegrationInstructions';
import DisconnectAccountButton from '../../../features/integrations/qm/settings/DisconnectAccountButton';
import { updateDefaultListOwner } from '../../../features/integrations/qm/integrationApi';
import BasicSetting from '../../../components/BasicSetting';
import useGetPersonPickerSearchOwnerData from '../../../components/personPicker/useGetPersonPickerOwnerData';
import { OrganizationSettingKey, updateSetting } from '../../../features/settings/organizationSettingsApi';
import useFetchIntegration from '../../../features/integrations/qm/useFetchIntegration';

interface IntegrationsSettingsPageProps {
  defaultListOwnerFromServer: string | undefined;
  generateListsFromServer: boolean | undefined;
  newLocationsGenerateListsFromServer: boolean | undefined;
}

const IntegrationsSettingsPage: React.FC<IntegrationsSettingsPageProps> = ({
  defaultListOwnerFromServer,
  generateListsFromServer,
  newLocationsGenerateListsFromServer,
}) => {
  const [defaultListOwner, setDefaultListOwner] = useState<string | undefined>(() => defaultListOwnerFromServer);
  const [generateLists, setGenerateLists] = useState<boolean | undefined>(() => generateListsFromServer);
  const [newLocationsGenerateLists, setNewLocationsGenerateLists] = useState<boolean | undefined>(
    () => newLocationsGenerateListsFromServer
  );

  const { people } = useGetPersonPickerSearchOwnerData();
  const { fetchIntegration } = useFetchIntegration(false);
  const { t } = useTranslation();

  const onDefaultListOwnerChange = async (personId: string) => {
    try {
      console.log('Default list owner changed to:', personId, '. Server Value was:', defaultListOwnerFromServer);
      await updateDefaultListOwner(personId);
      fetchIntegration();
      setDefaultListOwner(personId);
    } catch (error) {
      console.error('Failed to update QM default list owner!', error);
    }
  };

  const onToggleGenerateListChange = async (event: any) => {
    console.log('Generate List Toggle changed to:', event.detail.checked, '.  Server Value was ', generateLists);
    await updateSetting({ key: OrganizationSettingKey.auto_generate_deficiency_list, value: event.detail.checked });
    setGenerateLists(event.detail.checked);
  };

  const onToggleNewSLChange = async (event: any) => {
    console.log('New SL Toggle changed to:', event.detail.checked, '. Server Value was:', newLocationsGenerateLists);
    await updateSetting({
      key: OrganizationSettingKey.auto_generate_deficiency_list_for_new_location,
      value: event.detail.checked,
    });
    setNewLocationsGenerateLists(event.detail.checked);
  };

  return (
    <IntegrationsTabsPage tab="settings">
      <div className="ion-padding" style={{ maxWidth: '800px' }}>
        {/* Settings Header */}
        <div>
          <h1 className="ion-no-margin font-24 font-weight-400">Settings</h1>
        </div>
        {/* Settings Content */}
        <div style={{ padding: '1.25rem 2.5rem' }}>
          {/* Content sub-header */}
          <div className="d-flex ion-align-items-center">
            <h1 className="ion-no-margin font-20 font-weight-400">Your organization’s</h1>
            {/* A logo mid sentence does not really internationalize well */}
            <IonImg src={appLogo} />
            <h1 className="ion-no-margin font-20 font-weight-400">
              account is <b>connected</b>.
            </h1>
          </div>

          {/* Toggles buttons */}
          {/* #1 Toggle */}
          <BasicSetting
            label="Auto-generation of deficiency checklists"
            tooltip="When this is on, inspections done in Otuvy QM will create deficiency lists in Otuvy Frontline."
          >
            <IonToggle
              mode="ios"
              slot="start"
              enableOnOffLabels
              checked={generateLists}
              onIonChange={(e) => onToggleGenerateListChange(e)}
            />
          </BasicSetting>

          {/* #2 Toggle */}
          <BasicSetting
            label="Auto deficiency checklists for new locations"
            tooltip="When this is on, and a new location is added into Otuvy QM, auto deficiency lists will be generated in Otuvy Frontline for that location."
          >
            <IonToggle
              mode="ios"
              slot="start"
              enableOnOffLabels
              checked={newLocationsGenerateLists}
              onIonChange={(e) => onToggleNewSLChange(e)}
            />
          </BasicSetting>

          {/* Default List Owner */}
          <BasicSetting
            label="Default List Owner"
            tooltip="This Frontline user will receive all deficiency lists created and act as dispatch unless a default list owner is chosen on the locations page for the specific location."
            marginTop
          >
            <div className="ion-margin-end" style={{ width: '241px' }}>
              <PersonPickerSearch
                people={people}
                selectedPersonId={defaultListOwner}
                placeholder={t('placeholder.selectOne')}
                onIonChange={(e) => onDefaultListOwnerChange(e.detail.value)}
              />
            </div>
          </BasicSetting>
        </div>

        {/* More Info */}
        <div style={{ marginTop: '1.25rem' }}>
          <h1 className="ion-no-margin font-24 font-weight-400">More Information</h1>
        </div>
        <QmIntegrationInstructions />

        <div style={{ marginTop: '1.25rem' }}>
          <DisconnectAccountButton />
        </div>
      </div>
    </IntegrationsTabsPage>
  );
};

export default IntegrationsSettingsPage;
