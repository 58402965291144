import { Person } from '../features/people/peopleApi';
import { calculateTotalWorkingHours, sortWorkLogsByPunchtime } from '../features/timeTracking/utils/timeTrackingUtils';

export interface TimeTrackingEvent {
  punchId: string;
  workLogId: string;
  punchTime: Date;
  timeZoneOffset: number;
  punchType: TimeTrackingEventType;
  preciseLat?: number | null;
  preciseLong?: number | null;
  createdOn: Date;
}

export enum TimeTrackingEventType {
  UNKNOWN = 'unknown',
  CLOCK_IN = 'clockIn',
  CLOCK_OUT = 'clockOut',
}

export interface Punch {
  punchId: string | null;
  punchTime: Date | null;
  punchType: string;
  address: string | null;
  timeZoneOffset: number;
}

export interface WorkLog {
  workLogId: string;
  punchIn: Punch;
  punchOut: Punch;
}

export interface UserShiftData {
  userId: string;
  workLogs: WorkLog[];
}

export interface DetailedShiftData {
  userId: string;
  fullName: string;
  workLogId: string;
  punchInId: string;
  startDate: Date;
  startTime: number;
  punchOutId: string;
  endDate: Date | null;
  endTime: number | null;
  startAddress: string;
  endAddress: string;
  actionButton: '';
  totalWorkingHours: number;
}

export interface SummaryData {
  userId: string;
  fullName: string;
  totalWorkingHours: number;
}

export const generateSummaryViewData = (userShiftData: UserShiftData[], people: Person[]): SummaryData[] => {
  const userWorkLogs: SummaryData[] = userShiftData.map((user) => {
    const totalHours = user.workLogs.reduce((acc, worklog) => {
      const punchInTime = new Date(worklog.punchIn.punchTime!);
      const punchOutTime = worklog.punchOut.punchTime ? new Date(worklog.punchOut.punchTime) : null;
      return acc + calculateTotalWorkingHours(punchInTime, punchOutTime);
    }, 0);
    const person = people.find((employee) => employee.userId === user.userId);
    const personFullName = person ? `${person.firstName}  ${person.lastName}` : '';

    return {
      userId: user.userId,
      fullName: personFullName,
      totalWorkingHours: totalHours,
    } as SummaryData;
  });

  return userWorkLogs;
};

export const generateDetailsViewData = (userShiftData: UserShiftData[], people: Person[]): DetailedShiftData[] => {
  console.log('userShiftData', userShiftData);

  const userWorkLogs: DetailedShiftData[] = userShiftData.flatMap((user) => {
    const sortedWorkLogs = sortWorkLogsByPunchtime(user.workLogs);
    const userWorklogs = sortedWorkLogs.map((worklog) => {
      const punchInTime = new Date(worklog.punchIn.punchTime!);
      const punchOutTime = worklog.punchOut.punchTime ? new Date(worklog.punchOut.punchTime) : null;
      const person = people.find((employee) => employee.userId === user.userId);
      const personFullName = person ? `${person.firstName}  ${person.lastName}` : '';
      return {
        userId: user.userId,
        fullName: personFullName,
        workLogId: worklog.workLogId,
        punchInId: worklog.punchIn.punchId,
        startDate: punchInTime,
        startTime: punchInTime.getTime(),
        punchOutId: worklog.punchOut.punchId,
        endDate: punchOutTime,
        endTime: punchOutTime ? punchOutTime.getTime() : null,
        startAddress: worklog.punchIn.address ?? '',
        endAddress: worklog.punchOut.address ?? '',
        actionButton: '', //This property is not used but is needed to avoid wierd behavior in the grid
        totalWorkingHours: calculateTotalWorkingHours(punchInTime, punchOutTime), // Calculate total hours only if punchOutTime is available
      } as DetailedShiftData;
    });
    return userWorklogs;
  });

  return userWorkLogs;
};
