import { useState } from 'react';
import './CustomTimePicker.css';

interface CustomTimePickerProps {
  value: string; // This value should be a time string in the format "HH:MM"
  onChange?: (value: string) => void;
  onError?: (message: string) => void;
}

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({ value, onChange, onError }) => {
  const [timeValue, setTimeValue] = useState<string>(value);

  const onValueChangeEvent = (value: string) => {
    const [hours, minutes] = value.split(':');

    if (
      Number(hours) > 23 ||
      Number(hours) < 0 ||
      Number(minutes) > 59 ||
      Number(minutes) < 0 ||
      isNaN(Number(hours)) ||
      isNaN(Number(minutes))
    ) {
      onError && onError('Invalid time value');
      setTimeValue(value);
      return;
    }

    setTimeValue(value);
    onChange && onChange(value);
  };

  return (
    <div>
      <input
        className="custom-time-picker"
        type="time"
        value={timeValue}
        onChange={(e) => onValueChangeEvent(e.target.value)}
      />
    </div>
  );
};

export default CustomTimePicker;
