import IntegrationsTabsPage from './IntegrationsTabsPage';
import { FlexGrid } from '@grapecity/wijmo.react.grid';
import { FlexGridSearch } from '@grapecity/wijmo.react.grid.search';
import { useEffect, useRef, useState } from 'react';
import IntegrationsLocationsGrid from '../../../features/integrations/qm/locations/IntegrationsLocationsGrid';
import Tooltip from '../../../components/overlays/Tooltip';
import { IonSearchbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';

const IntegrationsLocationsPage = () => {
  const gridRef = useRef<FlexGrid>(null);
  const searchRef = useRef<FlexGridSearch>(null);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (gridRef.current && searchRef.current) searchRef.current.control.grid = gridRef.current.control;
  }, []);

  return (
    <IntegrationsTabsPage tab="locations">
      <div className="ion-padding" style={{ maxWidth: '1400px' }}>
        {/* Header */}
        <div
          className="d-flex ion-align-items-center ion-padding-bottom"
          style={{ position: 'sticky', top: '-16px', zIndex: '10000', background: 'var(--ion-background-color)' }}
        >
          <div className="d-flex ion-align-items-center">
            <h1 className="ion-no-margin font-24 font-weight-400">Locations & List Owners</h1>
          </div>
          {/* Searchbar */}
          <div className="ion-margin-start" style={{ flex: '1' }}>
            <IonSearchbar
              mode="ios"
              cancelButtonIcon={closeOutline}
              placeholder="Search for locations"
              value={searchText}
              onIonChange={(e) => setSearchText(e.target.value as string)}
            />
            {/* We keep the FlexGridSearch component hidden, 
                but it is needed to handle all the search for us, 
                but we were force to use the IonSearchbar component 
                to expose the searchText value 

              I need to explain this better, but that is the basic idea 
             */}
            <div style={{ visibility: 'hidden', display: 'none' }}>
              <FlexGridSearch ref={searchRef} placeholder="Search" grid={gridRef} text={searchText} />
            </div>
          </div>
        </div>

        {/* Content */}
        <div>
          {/* Locations Grid */}
          {/* Grid Headers= */}
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 150px 300px',
              position: 'sticky',
              top: '56px',
              zIndex: '10000',
              borderTop: '1px solid rgba(0,0,0,.2)',
              borderLeft: '1px solid rgba(0,0,0,.2)',
              borderRight: '1px solid rgba(0,0,0,0)',
            }}
          >
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Clients
            </div>
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Locations
            </div>
            <div
              className="wj-cell wj-header d-flex ion-center-align-items-center ion-justify-content-center"
              style={{ position: 'relative' }}
            >
              <div style={{ marginRight: '0.25rem' }}>Auto-lists</div>
              <Tooltip
                tooltip={
                  'When this is turned on and the auto generation toggle on the settings page is on, auto deficiency lists will be created in Otuvy Frontline for this location.'
                }
              />
            </div>
            <div className="wj-cell wj-header" style={{ position: 'relative' }}>
              Default List Owner
            </div>
          </div>

          {/* Wijmo Grid */}
          <IntegrationsLocationsGrid ref={gridRef} searchText={searchText} />
        </div>
      </div>
    </IntegrationsTabsPage>
  );
};

export default IntegrationsLocationsPage;
