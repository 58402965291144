import { CollectionView, PageChangingEventArgs } from '@grapecity/wijmo';
import { DataMap, RowCollection, FlexGrid as FlexGridClass } from '@grapecity/wijmo.grid';
import { FlexGrid, FlexGridCellTemplate, FlexGridColumn, ICellTemplateContext } from '@grapecity/wijmo.react.grid';
import { CollectionViewNavigator } from '@grapecity/wijmo.react.input';
import { forwardRef, useEffect, useState } from 'react';
import LoadingMessage from '../../../../components/overlays/LoadingMessage';
import PersonPickerSearch from '../../../../components/personPicker/PersonPickerSearch';
import useGetPersonPickerSearchOwnerData from '../../../../components/personPicker/useGetPersonPickerOwnerData';
import useGraphqlQuery from '../../../../hooks/useGraphqlQuery';
import { Person } from '../../../people/peopleApi';
import { QmLocation, getQmServiceLocations, updateQmServiceLocation } from '../integrationApi';

import './IntegrationsLocationsGrid.css';
import useErrorMessage from '../../../../error/useErrorMessage';

const PAGE_SIZE = 50;

interface QmLocationRow extends QmLocation {
  showClient: boolean;
}

interface IntegrationsLocationsGridProps {
  searchText: string;
}
type IntegrationsLocationsGridRef = FlexGrid;

const IntegrationsLocationsGrid = forwardRef<IntegrationsLocationsGridRef, IntegrationsLocationsGridProps>(
  (props, ref) => {
    const { data, refreshData, isLoading, setData } = useGraphqlQuery(getQmServiceLocations);
    const { handleError } = useErrorMessage(refreshData);
    const [pageNumber, setPageNumber] = useState(0);

    const [collectionView, setCollectionView] = useState(new CollectionView<QmLocationRow>([]));

    const { people, defaultListOwner } = useGetPersonPickerSearchOwnerData();

    useEffect(() => {
      console.info(`Data has changed: ${data ? data?.length : null} items`);
      rebuildCollectionView();
    }, [data]);

    useEffect(() => {
      console.info('isLoading:', isLoading);
    }, [isLoading]);

    const localRefreshData = () => {
      //if (data && data?.length < RELOAD_THRESHOLD) refreshData();
    };

    const hideAllClientsExceptFirstInstanceOfEachOnPage = (rows: RowCollection): void => {
      console.log('Hiding clients except first instance of each on page');
      const clients: string[] = [];

      rows.forEach((row, index) => {
        const item: QmLocationRow = row.dataItem;
        if (index === 0) {
          clients.push(item.clientName);
          return;
        }

        if (clients.includes(item.clientName)) {
          row.cssClass = 'hide-client';
        } else {
          clients.push(item.clientName);
        }
      });
    };

    const rebuildCollectionView = () => {
      console.log('building collectionView');
      // We need to create a CollectionView to handle pagination
      // https://developer.mescius.com/wijmo/demos/Grid/PagingScrolling/Client-sidePaging/react
      const newView = new CollectionView<QmLocationRow>(data, {
        pageSize: PAGE_SIZE,
        pageChanging: (cv: CollectionView<QmLocationRow>, { newPageIndex }: PageChangingEventArgs) => {
          if (pageNumber !== newPageIndex) setPageNumber(newPageIndex);
        },
      });

      newView.moveToPage(pageNumber); // Since changing the data triggers a re-render, we need to move back to the page we were on.  This assumes that there was no change in the data between the time the data was loaded and the time the collectionView was created.

      setCollectionView(newView);
    };

    const onloadedRows = (grid: FlexGridClass) => {
      hideAllClientsExceptFirstInstanceOfEachOnPage(grid.rows);
    };

    const createListOwnerDataMap = (peopleArr: Person[] | null) => {
      if (!peopleArr) return '';
      // We need to create a object map to use as a DataMap for the defaultListOwner column
      // This is so we can filter the listOwner column by the full name of the person intead of the userId
      const mapPeopleIdWithFullName = peopleArr?.map((person) => ({
        userId: person.userId,
        fullName: person.firstName + ' ' + person.lastName,
      }));

      // We pass this DataMap to the listOwner column
      return new DataMap(mapPeopleIdWithFullName, 'userId', 'fullName');
    };

    const handleAutoListsClick = (item: QmLocationRow) => {
      setData((prev) => {
        if (prev)
          return prev.map((d) => {
            if (d.serviceLocationId === item.serviceLocationId) {
              return { ...d, autoGenerateLists: !item.autoGenerateLists };
            }
            return d;
          });
        return prev;
      });

      updateQmServiceLocation({
        serviceLocationId: item.serviceLocationId,
        autoGenerateLists: !item.autoGenerateLists,
        defaultListOwner: item.defaultListOwner,
      })
        .catch((error: any) => {
          console.error('Failed to update QM service location', error);
          handleError(true);
        })
        .finally(localRefreshData);
    };

    const handleDefaultListOwnerChange = (item: QmLocationRow, newOwnerId: string) => {
      setData((prev) => {
        if (prev)
          return prev.map((d) => {
            if (d.serviceLocationId === item.serviceLocationId) {
              return { ...d, defaultListOwner: newOwnerId };
            }
            return d;
          });
        return prev;
      });

      updateQmServiceLocation({
        serviceLocationId: item.serviceLocationId,
        autoGenerateLists: item.autoGenerateLists,
        defaultListOwner: newOwnerId,
      })
        .catch((error: any) => {
          console.error('Failed to update QM service location', error);
          handleError(true);
        })
        .finally(localRefreshData);
    };

    return (
      <div className="container-fluid">
        <FlexGrid
          ref={ref}
          itemsSource={collectionView}
          loadedRows={onloadedRows}
          alternatingRowStep={0}
          headersVisibility="None"
        >
          {/* Columns */}

          {/* Clients */}
          <FlexGridColumn cssClass="client-column" header="Clients" binding="clientName" width="*" isReadOnly />

          {/* Locations */}
          <FlexGridColumn header="Locations" binding="serviceLocationName" width="*" isReadOnly />

          {/* Auto-lists button*/}
          <FlexGridColumn
            cssClass="auto-lists-column"
            header="Auto-lists"
            binding="autoGenerateLists"
            width={150}
            allowSorting={false}
            allowResizing={false}
            isReadOnly
          >
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: ICellTemplateContext) => (
                <div
                  className={`auto-lists-cell ${
                    context.item.autoGenerateLists ? 'auto-lists-cell-enabled' : 'auto-lists-cell-disabled'
                  }`}
                  onClick={() => handleAutoListsClick(context.item)}
                >
                  <span className="default-text">{context.item.autoGenerateLists ? 'On' : 'Off'}</span>
                  <span className="hover-text">
                    {context.item.autoGenerateLists ? 'Click to Turn Off' : 'Click to Turn On'}
                  </span>
                </div>
              )}
            />
          </FlexGridColumn>

          {/* Default List Owner DropDown*/}
          <FlexGridColumn
            header="Default List Owner"
            binding="defaultListOwner"
            width={300}
            allowSorting={false}
            allowResizing={false}
            isReadOnly
            dataMap={createListOwnerDataMap(people)}
          >
            <FlexGridCellTemplate
              cellType="Cell"
              template={(context: ICellTemplateContext) => (
                <PersonPickerSearch
                  people={people}
                  isInWijmoCell
                  selectedPersonId={context.item.defaultListOwner}
                  defaultListOwner={defaultListOwner}
                  searchText={props.searchText}
                  onIonChange={(e) => {
                    handleDefaultListOwnerChange(context.item, e.detail.value);
                  }}
                />
              )}
            />
          </FlexGridColumn>
        </FlexGrid>

        {/* Pagination */}
        <div className="d-flex ion-justify-content-center">
          <CollectionViewNavigator
            headerFormat="Page {currentPage:n0} of {pageCount:n0}"
            byPage={true}
            cv={collectionView}
          />
        </div>

        {/* Loading */}
        {isLoading && <LoadingMessage />}
      </div>
    );
  }
);

export default IntegrationsLocationsGrid;
