import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
} from '@ionic/react';

import {
  cardOutline,
  ellipse,
  listOutline,
  peopleOutline,
  settingsOutline,
  statsChartOutline,
  hourglassOutline,
} from 'ionicons/icons';
import './Menu.css';
import { useRecoilValue } from 'recoil';
import { organizationState } from '../../organization/useFetchOrganization';
import useStripe from '../../stripe/useStripe';
import { useTranslation } from 'react-i18next';
import { EnvironmentConfig, getFlag } from '../../../utils/environmentUtils';
import linkIcon from '../../../assets/images/link-custom-icon.svg';
import { integrationState } from '../../integrations/qm/useFetchIntegration';
import { integrationLocationAlertState } from '../../../pages/integrations/qm/IntegrationsTabsPage';
import { AuthCache, UserGroups } from '@otuvy/auth';

interface MenuProps {
  history?: any;
  contentId: string;
}

const Menu: React.FC<MenuProps> = (props) => {
  const organization = useRecoilValue(organizationState);
  const integrationInfo = useRecoilValue(integrationState);
  const showLocationsAlert = useRecoilValue(integrationLocationAlertState);

  const { redirectToStripe } = useStripe();
  const { t } = useTranslation();

  return (
    <IonMenu contentId={props.contentId} type="overlay">
      <IonContent>
        <IonList className="side-menu ion-no-padding">
          {AuthCache.isCurrentUserInAnyGroup([UserGroups.SUPER_ADMIN, UserGroups.PEOPLE_MANAGER]) && (
            <IonAccordionGroup value="people">
              <IonAccordion value="people">
                <IonItem slot="header" lines="none">
                  <IonIcon slot="start" color="secondary" icon={peopleOutline} />
                  <IonLabel>{t('title.people')}</IonLabel>
                </IonItem>
                <div slot="content" className="child-menu">
                  <IonItem lines="none" button routerLink="/people/list" routerDirection="none">
                    <IonIcon slot="start" color="secondary" />
                    <IonLabel className="ion-padding-start">{t('navigation.view')}</IonLabel>
                  </IonItem>
                  <IonItem lines="none" button routerLink="/people/add" routerDirection="none">
                    <IonIcon slot="start" color="secondary" />
                    <IonLabel className="ion-padding-start">{t('navigation.add')}</IonLabel>
                  </IonItem>
                </div>
              </IonAccordion>
            </IonAccordionGroup>
          )}
          {organization?.stripeCustomerId &&
            AuthCache.isCurrentUserInAnyGroup([UserGroups.SUPER_ADMIN, UserGroups.PEOPLE_MANAGER]) && (
              <IonItem button onClick={redirectToStripe} lines="none">
                <IonIcon slot="start" color="secondary" icon={cardOutline} />
                <IonLabel>{t('title.billing')}</IonLabel>
              </IonItem>
            )}

          {AuthCache.isCurrentUserInAnyGroup([UserGroups.LIST_MANAGER, UserGroups.SUPER_ADMIN]) ? (
            getFlag(EnvironmentConfig.LIST_IMPORT) ? (
              <IonAccordionGroup value="list">
                <IonAccordion value="list">
                  <IonItem slot="header" lines="none">
                    <IonIcon slot="start" color="secondary" icon={listOutline} />
                    <IonLabel>{t('title.lists')}</IonLabel>
                  </IonItem>
                  <div slot="content" className="child-menu">
                    <IonItem lines="none" button routerLink="/lists/view" routerDirection="none">
                      <IonIcon slot="start" color="secondary" />
                      <IonLabel className="ion-padding-start">{t('navigation.view')}</IonLabel>
                    </IonItem>
                    <IonItem lines="none" button routerLink="/lists/import" routerDirection="none">
                      <IonIcon slot="start" color="secondary" />
                      <IonLabel className="ion-padding-start">{t('navigation.import')} (Beta)</IonLabel>
                    </IonItem>
                  </div>
                </IonAccordion>
              </IonAccordionGroup>
            ) : (
              <IonItem button routerLink="/lists" routerDirection="none" lines="none">
                <IonIcon slot="start" color="secondary" icon={listOutline} />
                <IonLabel>{t('title.lists')}</IonLabel>
              </IonItem>
            )
          ) : null}

          {AuthCache.isCurrentUserInAnyGroup([UserGroups.TIME_TRACKING, UserGroups.SUPER_ADMIN]) && (
            <IonItem button routerLink="/timeTracking" routerDirection="none" lines="none">
              <IonIcon slot="start" color="secondary" icon={hourglassOutline} />
              <IonLabel>{t('title.timeTracking')} (Beta)</IonLabel>
            </IonItem>
          )}

          {getFlag(EnvironmentConfig.SHOW_INTEGRATION_LINKING) &&
            AuthCache.isCurrentUserInAnyGroup([UserGroups.SUPER_ADMIN]) && (
              <IonItem button routerLink="/usage" routerDirection="none" lines="none">
                <IonIcon slot="start" color="secondary" icon={statsChartOutline} />
                <IonLabel>{t('title.usage')}</IonLabel>
              </IonItem>
            )}

          {/* List Archive */}
          {getFlag(EnvironmentConfig.SHOW_SETTINGS) && AuthCache.isCurrentUserInAnyGroup([UserGroups.SUPER_ADMIN]) && (
            <IonItem button routerLink="/settings" routerDirection="none" lines="none">
              <IonIcon slot="start" color="secondary" icon={settingsOutline} />
              <IonLabel>{t('title.settings')}</IonLabel>
            </IonItem>
          )}

          {getFlag(EnvironmentConfig.SHOW_INTEGRATION_LINKING) &&
            AuthCache.isCurrentUserInAnyGroup([UserGroups.QM_INTEGRATION_MANAGER, UserGroups.SUPER_ADMIN]) && (
              <>
                {!integrationInfo?.isConnected || !getFlag(EnvironmentConfig.SHOW_INTEGRATION_SUB_PAGES) ? (
                  <IonItem button routerLink="/linkQM" routerDirection="none" lines="none">
                    <IonIcon slot="start" color="secondary" icon={linkIcon} />
                    <IonLabel>{t('title.integrations.qm')}</IonLabel>
                  </IonItem>
                ) : (
                  <IonAccordionGroup value="integration">
                    <IonAccordion value="integration">
                      <IonItem slot="header" lines="none">
                        <IonIcon slot="start" color="secondary" icon={linkIcon} />
                        <IonLabel>{t('title.integrations.qm')}</IonLabel>
                      </IonItem>
                      <div slot="content" className="child-menu">
                        <IonItem lines="none" button routerLink="/linkQM/settings" routerDirection="none">
                          <IonIcon slot="start" color="secondary" />
                          <IonLabel className="ion-padding-start">{t('title.integrations.qm.settings')}</IonLabel>
                        </IonItem>
                        <IonItem lines="none" button routerLink="/linkQM/locations" routerDirection="none">
                          <IonIcon slot="start" color="secondary" />
                          <div className="d-flex ion-align-items-center">
                            <IonLabel className="ion-padding-start">{t('title.integrations.qm.locations')}</IonLabel>
                            {showLocationsAlert && (
                              <IonIcon
                                icon={ellipse}
                                style={{ color: '#0032B2', fontSize: '1.125rem', marginLeft: '.5rem' }}
                              />
                            )}
                          </div>
                        </IonItem>
                      </div>
                    </IonAccordion>
                  </IonAccordionGroup>
                )}
              </>
            )}
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

export default Menu;
